






























































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import TopicModalCreate from '@/partials/modals/Questions/Topics/create.vue'
import TopicModalEdit from '@/partials/modals/Questions/Topics/edit.vue'
import TopicModalDelete from '@/partials/modals/Questions/Topics/delete.vue'

type SubmitType = 'create' | 'edit' | 'delete'

@Component({
  components: {
    TablePagination,
    Settings16,
    TrashCan16,
    Edit16,
    Add16,
    TopicModalCreate,
    TopicModalEdit,
    TopicModalDelete
  }
})
export default class QuestionsTopicsIndex extends Mixins(PermissionsMixin, FlashMessageMixin) {
  editModalIsOpened = false
  deleteModalIsOpened = false
  createModalIsOpened = false
  filteredData = []
  topics = []
  selectedTopic = {}
  topicsTotal = 0

  fetchTopics (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get('topic', {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.filteredData = this.topics = camelCaseKeys(response.data.data)
        this.topicsTotal = response.data.total
      })
  }

  search (searchString: string) {
    this.filteredData = this.topics.filter((topic: Record<string, any>) => {
      let { title } = topic
      title = title.toLowerCase()
      searchString = searchString.toLowerCase()

      if (title.includes(searchString)) {
        return topic
      }
    })
  }

  postSubmit (type: SubmitType) {
    let verb = ''
    const closeModal = {
      create: () => {
        this.createModalIsOpened = false
        verb = 'cadastrado'
      },
      edit: () => {
        this.editModalIsOpened = false
        verb = 'editado'
      },
      delete: () => {
        this.deleteModalIsOpened = false
        verb = 'removido'
      }
    }

    closeModal[type]()

    const flashMessage = {
      message: `O tópico foi ${verb} com sucesso.`,
      isSuccess: true
    }

    this.setFlashMessage(flashMessage)

    this.fetchTopics()
  }

  onPageChange (pagination: Pagination) {
    this.fetchTopics(pagination.page, pagination.length)
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
