












































import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

@Component({
  components: {
    PasswordConfirmField,
    ValidationObserver,
    ValidationProvider
  }
})
export default class QuestionTopicCreate extends Mixins(ValidatorConfigMixin) {
  @Prop({ default: false }) visible!: boolean
  form: Record<string, any> = {
    title: '',
    confirm: '',
    errors: {
      title: '',
      confirm: ''
    }
  }

  closeModal () {
    this.$emit('close')
  }

  submit () {
    this.form.errors = {
      title: '',
      confirm: ''
    }
    axios.post('topic', this.form)
      .then(response => {
        this.$emit('submitted', response.data.data)
        this.resetForm()
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  $refs!: {
    observer: any;
  }

  resetForm () {
    this.form = {
      title: '',
      confirm: '',
      errors: {
        title: '',
        confirm: ''
      }
    }
    this.$refs.observer.reset()
  }
}
