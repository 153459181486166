




































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class QuestionTopicEdit extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop() topic!: Topic

  form: Record<string, any> = {
    title: '',
    confirm: '',
    errors: {
      title: '',
      confirm: ''
    }
  }

  @Watch('topic', { immediate: true })
  onTopicChange () {
    this.form.title = this.topic.title
  }

  closeModal () {
    this.resetForm()
    this.$emit('close')
  }

  submit () {
    this.form.errors = {
      title: '',
      confirm: ''
    }
    axios.put(`topic/${this.topic.id}`, this.form)
      .then(response => {
        this.$emit('submitted', response.data.data)
        this.resetForm()
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  $refs!: {
    observer: any;
  }

  resetForm () {
    this.form.confirm = ''
    this.form.errors.title = ''
    this.form.errors.confirm = ''

    this.$refs.observer.reset()
  }
}
